import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetFormsState = createAction('RESET_FORMS_STATE')

export const updateContentForms = createAction('UPDATE_CONTENT_FORMS')
export const addContentForm = createAction('ADD_CONTENT_FORM')
export const removeContentForm = createAction('REMOVE_CONTENT_FORM')
export const updateForm = createAction('UPDATE_FORM')
export const updateAvailableForms = createAction('UPDATE_AVAILABLE_FORMS')
export const getFormControls = createAction('GET_FORM_CONTROLS')
export const setFormAnswers = createAction('SET_FORM_ANSWERS')
export const updateFormAnswers = createAction('UPDATE_FORM_ANSWERS')
export const clearFormAnswers = createAction('CLEAR_FORM_ANSWERS')
export const setSelectedTabs = createAction('SET_SELECTED_TABS')
export const setSelectedUserId = createAction('SET_SELECTED_USER_ID')
export const setCurrentForm = createAction('SET_CURRENT_FORM')
export const unsetCurrentForm = createAction('UNSET_CURRENT_FORM')
export const updateReattachCount = createAction('UPDATE_REATTACH_COUNT')
export const updateContentForm = createAction('UPDATE_CONTENT_FORM')

export const getContentFormsRequest = createAction('GET_CONTENT_FORMS_REQUEST')
export const getContentFormsSuccess = createAction('GET_CONTENT_FORMS_SUCCESS')
export const getContentFormsFailed = createAction('GET_CONTENT_FORMS_FAILED')

export const getAvailableFormsRequest = createAction('GET_AVAILABLE_FORMS_REQUEST')
export const getAvailableFormsSuccess = createAction('GET_AVAILABLE_FORMS_SUCCESS')

export const getFormControlsRequest = createAction('GET_FORM_CONTROLS_REQUEST')
export const getFormControlsSuccess = createAction('GET_FORM_CONTROLS_SUCCESS')

export const setCurrentTabs = createAction('SET_CURRENT_TABS')

export const addFormAnswers = createAction('ADD_FORM_ANSWERS')
export const sendFormAnswerRequest = createAction('SEND_FORM_ANSWER_REQUEST')
export const sendFormAnswerSuccess = createAction('SEND_FORM_ANSWER_SUCCESS')

export const getFormAnswersRequest = createAction('GET_FORM_ANSWERS_REQUEST')
export const getFormAnswersSuccess = createAction('GET_FORM_ANSWERS_SUCCESS')

export const getTabsAnswersRequest = createAction('GET_TABS_ANSWERS_REQUEST')
export const getTabsAnswersSuccess = createAction('GET_TABS_ANSWERS_SUCCESS')

export const attachFormRequest = createAction('ATTACH_FORM_REQUEST')
export const attachFormSuccess = createAction('ATTACH_FORM_SUCCESS')
export const attachFormFailed = createAction('ATTACH_FORM_FAILED')

export const prepareAttachingForm = createAction('PREPARE_ATTACHING_FORM')
export const prepareDetachingForm = createAction('PREPARE_DETACHING_FORM')

export const detachFormRequest = createAction('DETACH_FORM_REQUEST')
export const detachFormSuccess = createAction('DETACH_FORM_SUCCESS')
export const detachFormFailed = createAction('DETACH_FORM_FAILED')

export const detachCurrentForm = createAction('DETACH_CURRENT_FORM')

export const exportFormAnswersRequest = createAction('EXPORT_FORM_ANSWERS_REQUEST')
export const exportFormAnswersSuccess = createAction('EXPORT_FORM_ANSWERS_SUCCESS')

export const exportFormToCsv = createAction('EXPORT_FORM_TO_CSV')
export const exportFormToCsvRequest = createAction('EXPORT_FORM_TO_CSV_REQUEST')
export const exportFormToCsvSuccess = createAction('EXPORT_FORM_TO_CSV_SUCCESS')
export const exportFormToCsvFailed = createAction('EXPORT_FORM_TO_CSV_FAILED')

export const exportFormToCustomCsv = createAction('EXPORT_FORM_TO_CUSTOM_CSV')
export const apiExportFormToCustomCsv = createApiActions('EXPORT_FORM_TO_CUSTOM_CSV')

export const setExportFormsToCsvOptionState = createAction('SET_EXPORT_FORMS_TO_CSV_OPTION_STATE')
export const openExportFormsToCsvDialog = createAction('OPEN_EXPORT_FORMS_TO_CSV_DIALOG')
export const closeExportFormsToCsvDialog = createAction('CLOSE_EXPORT_FORMS_TO_CSV_DIALOG')

export const exportFormToPdf = createAction('EXPORT_FORM_TO_PDF')
export const exportFormToPdfRequest = createAction('EXPORT_FORM_TO_PDF_REQUEST')
export const exportFormToPdfSuccess = createAction('EXPORT_FORM_TO_PDF_SUCCESS')
export const exportFormToPdfFailed = createAction('EXPORT_FORM_TO_PDF_FAILED')

export const clearFormAnswer = createAction('CLEAR_FORM_ANSWER')
export const clearFormAnswerRequest = createAction('CLEAR_FORM_ANSWER_REQUEST')
export const clearFormAnswerSuccess = createAction('CLEAR_FORM_ANSWER_SUCCESS')

export const updateAnswerStatusRequest = createAction('UPDATE_ANSWER_STATUS_REQUEST')
export const updateAnswerStatusSuccess = createAction('UPDATE_ANSWER_STATUS_SUCCESS')
export const updateAnswerStatus = createAction('UPDATE_ANSWER_STATUS')

export const getAnswersStatusesCountRequest = createAction('GET_ANSWERS_STATUSES_COUNT_REQUEST')
export const getAnswersStatusesCountSuccess = createAction('GET_ANSWERS_STATUSES_COUNT_SUCCESS')
export const setAnswersStatusesCount = createAction('SET_ANSWERS_STATUSES_COUNT')

export const proceedToControlAnswerPage = createAction('PROCEED_TO_CONTROL_ANSWER_PAGE')

export const getContentFormRequest = createAction('GET_CONTENT_FORM_REQUEST')
export const getContentFormSuccess = createAction('GET_CONTENT_FORM_SUCCESS')

export const formAttached = createAction('FORM_ATTACHED')
export const formDetached = createAction('FORM_DETACHED')
export const formUpdated = createAction('FORM_UPDATED')

export const setDisplayAnswersMode = createAction('SET_DISPLAY_ANSWERS_MODE')

export const getLatestFormAnswers = createAction('GET_LATEST_FORM_ANSWERS')
export const getLatestFormAnswersRequest = createAction('GET_LATEST_FORM_ANSWERS_REQUEST')
export const getLatestFormAnswersSuccess = createAction('GET_LATEST_FORM_ANSWERS_SUCCESS')

export const getLatestTabsAnswersRequest = createAction('GET_LATEST_TABS_ANSWERS_REQUEST')
export const getLatestTabsAnswersSuccess = createAction('GET_LATEST_TABS_ANSWERS_SUCCESS')

export const getAllMyLatestAnswers = createAction('GET_ALL_MY_LATEST__ANSWERS')

export const openSwitchFormDialog = createAction('OPEN_SWITCH_FORM_DIALOG')
export const closeSwitchFormDialog = createAction('CLOSE_SWITCH_FORM_DIALOG')

export const setLastAttachedForm = createAction('SET_LAST_ATTACHED_FORM')

export const addFormAnswersFromFile = createAction('ADD_FORM_ANSWERS_FROM_FILE')
export const addFormAnswersFromFileRequest = createAction('ADD_FORM_ANSWERS_FROM_FILE_REQUEST')
export const addFormAnswersFromFileSuccess = createAction('ADD_FORM_ANSWERS_FROM_FILE_SUCCESS')
export const addFormAnswersFromFileFailed = createAction('ADD_FORM_ANSWERS_FROM_FILE_FAILED')

export const setFormAnswersFromFileLoadingState = createAction('SET_FORM_ANSWERS_FROM_FILE_LOADING_STATE')

export const setFormAnswersFileIsSelected = createAction('SET_FORM_ANSWERS_FILE_IS_SELECTED')
export const clearFormAnswersFileIsSelected = createAction('CLEAR_FORM_ANSWERS_FILE_IS_SELECTED')

export const setUploadFormAnswersErrors = createAction('SET_UPLOAD_FORM_ANSWERS_ERRORS')
export const clearUploadFormAnswersErrors = createAction('CLEAR_UPLOAD_FORM_ANSWERS_ERRORS')

export const getContentFormControlsRequest = createAction('GET_CONTENT_FORM_CONTROLS_REQUEST')
export const getContentFormControlsSuccess = createAction('GET_CONTENT_FORM_CONTROLS_SUCCESS')

export const openInfoMessageDialog = createAction('OPEN_INFO_MESSAGE_DIALOG')
export const closeInfoMessageDialog = createAction('CLOSE_INFO_MESSAGE_DIALOG')

export const checkLastAttachedForm = createAction('CHECK_LAST_ATTACHED_FORM')

export const callExternalApiRequest = createAction('CALL_EXTERNAL_API_REQUEST')
export const callExternalApiSuccess = createAction('CALL_EXTERNAL_API_SUCCESS')
export const callExternalApiFailed = createAction('CALL_EXTERNAL_API_FAILED')

export const closeFormActionDialog = createAction('CLOSE_FORM_ACTION_DIALOG')
export const showExternalApiResponse = createAction('SHOW_EXTERNAL_API_RESPONSE')

export const openForm = createAction('OPEN_FORM')
export const setInitialValues = createAction('SET_INITIAL_VALUES')

export const setAnswerStatusFilter = createAction('SET_ANSWER_STATUS_FILTER')

export const setRecords = createAction('SET_RECORDS')
export const setSelectedRecord = createAction('SET_SELECTED_RECORD')

export const saveFormRecord = createAction('SAVE_FORM_RECORD')
export const saveFormRecordRequest = createAction('SAVE_FORM_RECORD_REQUEST')
export const saveFormRecordSuccess = createAction('SAVE_FORM_RECORD_SUCCESS')
export const saveFormRecordFailed = createAction('SAVE_FORM_RECORD_FAILED')

export const addFormRecord = createAction('ADD_FORM_RECORD')
export const removeFormRecord = createAction('REMOVE_FORM_RECORD')

export const removeFormRecordRequest = createAction('REMOVE_FORM_RECORD_REQUEST')
export const removeFormRecordSuccess = createAction('REMOVE_FORM_RECORD_SUCCESS')
export const removeFormRecordFailed = createAction('REMOVE_FORM_RECORD_FAILED')

export const getFormAnswersByRecordRequest = createAction('GET_FORM_ANSWERS_BY_RECORD_REQUEST')
export const getFormAnswersByRecordSuccess = createAction('GET_FORM_ANSWERS_BY_RECORD_SUCCESS')
export const getFormAnswersByRecordFailed = createAction('GET_FORM_ANSWERS_BY_RECORD_FAILED')

export const setFormAnswersLoading = createAction('SET_FORM_ANSWERS_LOADING')
export const getCounterInfo = createAction('GET_COUNTER_INFO')

export const updateReviewerAnswerStatusRequest = createAction('UPDATE_REVIEWER_ANSWER_STATUS_REQUEST')
export const updateReviewerAnswerStatusSuccess = createAction('UPDATE_REVIEWER_ANSWER_STATUS_SUCCESS')
export const updateReviewerAnswerStatusFailed = createAction('UPDATE_REVIEWER_ANSWER_STATUS_FAILED')

export const setFormAnswerStatus = createAction('SET_FORM_ANSWER_STATUS')

export const getAvailableFormsByFolderIdRequest = createAction('GET_AVAILABLE_FORMS_BY_FOLDER_ID_REQUEST')
export const getAvailableFormsByFolderIdSuccess = createAction('GET_AVAILABLE_FORMS_BY_FOLDER_ID_SUCCESS')
export const getAvailableFormsByFolderIdFailed = createAction('GET_AVAILABLE_FORMS_BY_FOLDER_ID_FAILED')

export const getAllFoldersInFormsRequest = createAction('GET_ALL_FOLDERS_IN_FORMS_REQUEST')
export const getAllFoldersInFormsSuccess = createAction('GET_ALL_FOLDERS_IN_FORMS_SUCCESS')
export const getAllFoldersInFormsFailed = createAction('GET_ALL_FOLDERS_IN_FORMS_FAILED')

export const openFolder = createAction('OPEN_FOLDER_IN_FORMS')
export const setCurrentFolder = createAction('SET_CURRENT_FOLDER_IN_FORMS')
export const clearCurrentFolder = createAction('CLEAR_CURRENT_FOLDER_IN_FORMS')

export const clearFoldersList = createAction('CLEAR_FOLDERS_LIST_IN_FORMS')

export const getDeletedUserIdsWithAnsweredQuestions = createApiActions('GET_DELETED_USER_IDS_WITH_ANSWERED_QUESTIONS')

export const createUserSignature = createAction('CREATE_USER_SIGNATURE')
export const signForm = createAction('SIGN_FORM')
