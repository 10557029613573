import { put, takeLatest, all, select } from 'redux-saga/effects'
import { onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import * as rawActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'

function* callExternalApiRequest(action) {
  const message = 'Your request is processing. It may take up to 10 minutes.'
  yield put(onAddInfoNotification({ message }))
}

function* onExternalApiResponseReceived(action) {
  const [model] = action.payload
  const currentUserId = yield select(getCurrentUserId)

  if (currentUserId === model.UserId) {
    yield put(rawActions.showExternalApiResponse(model))
  }
}

function* onShowInfoMessage(action) {
  const [{ infoMessage, userId }] = action.payload
  const currentUserId = yield select(getCurrentUserId)

  if (currentUserId === userId) {
    yield put(rawActions.openInfoMessageDialog(infoMessage))
  }
}

function* formActions() {
  yield all([
    takeLatest(rawActions.callExternalApiRequest, callExternalApiRequest),
    takeLatest(
      signalrEvents[signalrConstants.tabeebHubName].onExternalApiResponseReceived,
      onExternalApiResponseReceived
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onShowInfoMessage, onShowInfoMessage),
  ])
}

export default formActions
