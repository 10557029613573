import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { FormActionDialogStatus } from '../services/enums'

const defaultState = []

export default handleActions(
  {
    [rawActions.closeFormActionDialog](state, { payload }) {
      return state.filter((activeRequest) => activeRequest.actionId !== payload)
    },
    [rawActions.callExternalApiRequest](state, { payload: { actionId } }) {
      const isRequestExist = state.some((activeRequest) => activeRequest.actionId === actionId)

      if (isRequestExist) {
        return state
      }
      const newRequest = {
        actionId,
        dialogStatus: FormActionDialogStatus.Hidden,
        responseData: [],
      }
      return [...state, newRequest]
    },
    [rawActions.showExternalApiResponse](state, { payload }) {
      const { FormActionId, ResponseData } = payload
      const isRequestExist = state.some((activeRequest) => activeRequest.actionId === FormActionId)

      if (isRequestExist) {
        return state.map((activeRequest) =>
          activeRequest.actionId === FormActionId
            ? {
                ...activeRequest,
                responseData: ResponseData,
                dialogStatus:
                  ResponseData === null ? FormActionDialogStatus.ShowError : FormActionDialogStatus.ShowData,
              }
            : activeRequest
        )
      }
      const newRequest = {
        actionId: FormActionId,
        dialogStatus: ResponseData === null ? FormActionDialogStatus.ShowError : FormActionDialogStatus.ShowData,
        responseData: ResponseData,
      }

      return [...state, newRequest]
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
