import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { FormActionDialogStatus } from '../../services/enums'
import AlarmsInformationTable from '../AlarmsInformationTable'

import './styles.less'

const AlarmsInformationDialog = ({ onCloseDialog, formActionDialogState, actionId }) => {
  const currentActionDialog = formActionDialogState.find((d) => d.actionId === actionId)

  if (!currentActionDialog) return null

  const { dialogStatus, responseData } = currentActionDialog
  let buttonText
  let dialogMessage
  const isOpen = dialogStatus !== FormActionDialogStatus.Hidden

  switch (dialogStatus) {
    case FormActionDialogStatus.ShowError:
      dialogMessage = 'Failed to get alarms information.'
      buttonText = 'Close'
      break
    case FormActionDialogStatus.ShowData:
      dialogMessage = responseData.length > 0 ? null : 'There are no new alarms.'
      buttonText = 'Ok'
      break
  }

  return (
    <Dialog open={isOpen} className='alarms-dialog'>
      <DialogContent className='alarms-dialog-content'>
        {dialogMessage && <DialogContentText className='alarms-dialog-content-text'>{dialogMessage}</DialogContentText>}
        {dialogStatus === FormActionDialogStatus.ShowData && responseData.length > 0 && (
          <AlarmsInformationTable alarmsList={responseData} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseDialog(actionId)} color='primary'>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlarmsInformationDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  formActionDialogState: PropTypes.array.isRequired,
  actionId: PropTypes.number.isRequired,
}

export default AlarmsInformationDialog
