import { combineActions, handleActions } from 'redux-actions'

import { updateUniqueAiObjectSuccess } from '@tabeeb/modules/artificialIntelligence/actions'
import { closeAiReportDialog, getAiObjectAnalysis, updateUniqueAiObjectFromJson } from '../actions'

const defaultState = null

export default handleActions(
  {
    [getAiObjectAnalysis.success](state, action) {
      return action.response.data
    },
    [combineActions(updateUniqueAiObjectSuccess, updateUniqueAiObjectFromJson.success)](state, action) {
      if (state === defaultState) {
        return defaultState
      }

      return {
        ...state,
        data: state.data.map((uniqueObject) =>
          uniqueObject.UniqueAIObjectId === action.response.data.Id
            ? {
                ...uniqueObject,
                UniqueAIObject: { ...uniqueObject.UniqueAIObject, ...JSON.parse(action.response.data.Properties) },
              }
            : uniqueObject
        ),
      }
    },
    [updateUniqueAiObjectFromJson.failed](state, action) {
      return { ...state, data: [...state.data] }
    },
    [closeAiReportDialog](state, action) {
      return defaultState
    },
  },
  defaultState
)
