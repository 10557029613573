import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import './styles.less'

const AlarmsInformationTable = ({ alarmsList }) => {
  const columns = Object.keys(alarmsList[0])

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((col) => {
            return (
              <TableCell colSpan={20} style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>{col}</span>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {alarmsList.map((alarm, index) => {
          return (
            <TableRow key={index}>
              {Object.keys(alarm).map((key, index) => {
                const value = alarm[key]
                const backgroundColor = key.toLowerCase() === 'severity' ? alarm.Severity : '#fff'
                return (
                  <TableCell colSpan={20} style={{ textAlign: 'center', backgroundColor }}>
                    <span style={{ fontSize: 15 }}>{key.toLowerCase() === 'severity' ? null : value}</span>
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

AlarmsInformationTable.propTypes = {
  alarmsList: PropTypes.array.isRequired,
}

export default AlarmsInformationTable
