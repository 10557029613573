import { memo } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import CurrentForm from '@tabeeb/modules/forms/components/CurrentForm'
import SwitchFormDialogContainer from '@tabeeb/modules/forms/containers/SwitchFormDialogContainer'
import InfoMessageDialogContainer from '@tabeeb/modules/forms/containers/InfoMessageDialogContainer'
import ExportFormsToCsvOptionsDialogContainer from '@tabeeb/modules/forms/containers/ExportFormsToCsvOptionsDialogContainer'
import AlarmsInformationDialogContainer from '@tabeeb/modules/forms/containers/AlarmsInformationDialogContainer'

import styles from './styles'

const Forms = ({ classes }) => {
  return (
    <section className={classes.container}>
      <CurrentForm />
      <InfoMessageDialogContainer />
      <SwitchFormDialogContainer />
      <ExportFormsToCsvOptionsDialogContainer />
      <AlarmsInformationDialogContainer />
    </section>
  )
}

Forms.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(Forms))
