import { useFormikContext } from 'formik'
import { DialogActions, Button } from '@mui/material'

import PropTypes from 'prop-types'

const DialogActionsWithFormik = ({ onClose }) => {
  const { isSubmitting, dirty, submitForm } = useFormikContext()

  return (
    <DialogActions>
      <Button onClick={submitForm} disabled={!dirty || isSubmitting}>
        Save
      </Button>
      <Button onClick={onClose} color='primary'>
        Cancel
      </Button>
    </DialogActions>
  )
}

DialogActionsWithFormik.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default DialogActionsWithFormik
