import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const setSelectedModel = createAction('SET_SELECTED_MODEL')
export const setBoundingBox = createAction('SET_BOUNDING_BOX')
export const setViewerBackground = createAction('SET_VIEWER_BACKGROUND')
export const setFieldOfView = createAction('SET_FIELD_OF_VIEW')
export const setPointSize = createAction('SET_POINT_SIZE')
export const setUnit = createAction('SET_UNIT')
export const toggleSourcePagesNavigation = createAction('TOGGLE_SOURCE_PAGES_NAVIGATION')

export const enableTransformation = createAction('ENABLE_TRANSFORMATION')
export const disableTransformation = createAction('DISABLE_TRANSFORMATION')
export const setTransformationMode = createAction('SET_TRANSFORMATION_MODE')

export const setWorldRotation = createAction('SET_WORLD_ROTATION')

export const getPointCloudAccessToken = createApiActions('GET_POINT_CLOUD_ACCESS_TOKEN')
export const getPointCloudSettings = createApiActions('GET_POINT_CLOUD_SETTINGS')
export const updatePointCloudSettings = createApiActions('UPDATE_POINT_CLOUD_SETTINGS')
