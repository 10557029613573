import { memo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { SupportedPointCloudExtensions } from '@tabeeb/modules/pointCloud/constants'
import { mimeTypes } from '@tabeeb/shared/uikit/constants'
import { Popover } from '@mui/material'
import ContentUniqueAiObjectsDialog from '@tabeeb/modules/utilityInfrastructure/components/ContentUniqueAiObjectsDialog'
import GalleryMenuItem from '../GalleryMenuItem'
import { getAddButtonMenuItems } from '../../selectors'
import { addMenuItems } from '../../services/galleryMenus'
import { addFilesToQueue, handleAddButtonMenuClick } from '../../actions'

const AddButtonMenu = ({ setAnchorEl, anchorEl }) => {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [selectedMenuItem, setSelectedMenuItem] = useState(null)
  const addButtonMenuItems = useSelector(getAddButtonMenuItems)

  const inputFileMenuItems = [
    addMenuItems.WordDocument,
    addMenuItems.PDF,
    addMenuItems.Images,
    addMenuItems.VideoFile,
    addMenuItems.PointCloudModel,
    addMenuItems.AutodeskFile,
  ]

  const _handleMenuClick = (menuItemType) => {
    setAnchorEl(null)
    setSelectedMenuItem(menuItemType)

    switch (menuItemType) {
      case addMenuItems.WordDocument:
        inputRef.current.accept = '.doc, .docx, .ppt, .pptx, .xls, .xlsx'
        break
      case addMenuItems.PDF:
        inputRef.current.accept = mimeTypes.pdf
        break
      case addMenuItems.Images:
        inputRef.current.accept = 'image/jpeg,image/gif,image/png,image/heic,image/heif'
        inputRef.current.multiple = true
        break
      case addMenuItems.VideoFile:
        inputRef.current.accept = 'video/*'
        inputRef.current.multiple = true
        break
      case addMenuItems.PointCloudModel:
        inputRef.current.accept = SupportedPointCloudExtensions.join(', ')
        break
      case addMenuItems.AutodeskFile:
        inputRef.current.accept = '.rvt, .dwg, .obj'
        break
      default:
        dispatch(handleAddButtonMenuClick({ menuItemType }))
        break
    }

    if (inputFileMenuItems.includes(menuItemType)) inputRef.current.click()
  }

  const _handleFileInputChange = (e) => {
    const files = [...e.target.files]
    e.target.value = null
    if (inputFileMenuItems.includes(selectedMenuItem)) {
      dispatch(addFilesToQueue({ files }))
    }
  }

  return (
    <>
      <Popover
        className='gallery-add-menu-popover'
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className='gallery-add-menu'>
          {addButtonMenuItems.map((menuItem) => (
            <GalleryMenuItem key={menuItem.menuItemType} menuItem={menuItem} handleMenuClick={_handleMenuClick} />
          ))}
        </div>
      </Popover>
      <input
        ref={inputRef}
        type='file'
        multiple={false}
        onChange={_handleFileInputChange}
        style={{ display: 'none' }}
      />
      <ContentUniqueAiObjectsDialog />
    </>
  )
}

AddButtonMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(HTMLElement),
  setAnchorEl: PropTypes.func.isRequired,
}

export default memo(AddButtonMenu)
