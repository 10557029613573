import { useDispatch, useSelector } from 'react-redux'
import { memo, useEffect, useState } from 'react'

import {
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  Dialog,
  FormGroup,
  Checkbox,
} from '@mui/material'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import {
  getParentsContentUniqueAiObjects,
  runUtilityInfrastructureAnalysis,
  setUniqueAiObjectsDialogState,
} from '../../actions'

const ContentUniqueAiObjectsDialog = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const dispatch = useDispatch()
  const { list, isLoading, isOpen } = useSelector((state) => state.utilityInfrastructure.uniqueAiObjectsDialogState)
  const contentId = useSelector(getContentId)

  useEffect(() => {
    if (isOpen) {
      dispatch(getParentsContentUniqueAiObjects.request(contentId))
    }
  }, [dispatch, isOpen, contentId])

  const _handleChange = (e) => {
    const { value } = e.target
    const isChecked = e.target.checked
    const numberValue = parseInt(value, 10)
    if (isChecked) {
      setSelectedIds([...selectedIds, numberValue])
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== numberValue))
    }
  }

  const _handleClose = () => {
    setSelectedIds([])
    dispatch(setUniqueAiObjectsDialogState({ isOpen: false }))
  }

  const _handleSubmit = () => {
    dispatch(runUtilityInfrastructureAnalysis.request({ uniqueAiObjectsIds: selectedIds, contentId }))
    _handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={_handleClose}>
      <DialogTitle>Unique object(s) for the analysis</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgressBar />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormGroup>
              {list.map((uniqueObject) => {
                const isChecked = selectedIds.includes(uniqueObject.Id)

                const props = {
                  checked: isChecked,
                  onChange: _handleChange,
                  value: uniqueObject.Id,
                }

                return (
                  <FormControlLabel
                    control={<Checkbox {...props} color='default' />}
                    label={uniqueObject.Name}
                    key={uniqueObject.Id}
                  />
                )
              })}
            </FormGroup>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={_handleSubmit} disabled={selectedIds.length === 0}>
          Run
        </Button>
        <Button variant='outlined' onClick={_handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(ContentUniqueAiObjectsDialog)
