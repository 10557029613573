const utilityInfrastructureRequests = {
  runUtilityInfrastructureAnalysisRequest: (data) => {
    return {
      url: `api/utility-infrastructure/run-analysis`,
      method: 'post',
      data,
    }
  },
  getParentsContentUniqueAiObjectsRequest: (contentId) => {
    return {
      url: `api/ai/uniqueObjects/content/${contentId}/parents`,
      method: 'get',
    }
  },
}

export default utilityInfrastructureRequests
