import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as formsActions from '../actions'
import AlarmsInformationDialog from '../components/AlarmsInformationDialog'

class AlarmsInformationDialogContainer extends Component {
  render() {
    const {
      actions: { closeFormActionDialog },
      actionsIds,
      formActionDialogState,
    } = this.props

    const props = {
      onCloseDialog: closeFormActionDialog,
      formActionDialogState,
    }
    return actionsIds.map((actionId) => <AlarmsInformationDialog {...props} actionId={actionId} key={actionId} />)
  }
}

AlarmsInformationDialogContainer.propTypes = {
  formActionDialogState: PropTypes.array.isRequired,
  actionsIds: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    closeFormActionDialog: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ forms: { formActionDialogState, contentForms } }) => {
  const actionsIds = []

  contentForms &&
    contentForms.map((contentForm) => {
      contentForm.Actions &&
        contentForm.Actions.map((action) => {
          actionsIds.push(action.Id)
        })
    })

  return {
    actionsIds,
    formActionDialogState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsInformationDialogContainer)
