import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { goToPage } from '@tabeeb/modules/spatialModel/actions'
import { openHotspotDialog } from '@tabeeb/modules/annotations/actions'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { updateUniqueAiObjectSuccess } from '@tabeeb/modules/artificialIntelligence/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import {
  closeAiReportPublishSettingsDialog,
  getAiObjectAnalysis,
  getAiObjectsByContent,
  getPublishTemplates,
  goToAnnotation,
  publishAiReport,
  selectAiObject,
  setAiReportNeedToRefresh,
  updateUniqueAiObjectFromJson,
} from '../actions'
import { getIsAIReportDialogOpen, getSelectedAiObjectId } from '../selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import AIReportDialog from '../components/AIReportDialog'

function* onSelectAiObject() {
  const selectedAiObjectId = yield select(getSelectedAiObjectId)

  if (selectedAiObjectId) {
    const contentId = yield select(getContentId)

    yield put(getAiObjectAnalysis.request({ contentId, aiObjectId: selectedAiObjectId }))
    yield put(getPublishTemplates.request({ aiObjectId: selectedAiObjectId }))
  }
}

function* onGetAiObjectsByContentSuccess({ response }) {
  const aiObjectIds = response.data.map((o) => o.Id)
  if (!aiObjectIds.length) {
    return
  }

  const selectedAiObjectId = yield select(getSelectedAiObjectId)
  if (!selectedAiObjectId || !aiObjectIds.includes(selectedAiObjectId)) {
    yield put(selectAiObject({ aiObjectId: aiObjectIds[0] }))
  }
}

function* onGoToAnnotation({ payload }) {
  const { PageId, AnnotationId } = payload

  const selectedGalleryItemId = yield select(getSelectedGalleryItemId)

  if (PageId !== selectedGalleryItemId) {
    yield put(goToPage(PageId))
  }

  yield put(openHotspotDialog({ annotationId: AnnotationId }))
}

function* onUniqueAIObjectUpdated(action) {
  const [model] = action.payload
  const currentUserId = yield select(getCurrentUserId)
  if (currentUserId === model.UserId) {
    return
  }

  const isDialogOpen = yield select(getIsAIReportDialogOpen)
  if (isDialogOpen) {
    yield put(setAiReportNeedToRefresh(true))
  }
}

function* onAnnotationMutate(action) {
  const isDialogOpen = yield select(getIsAIReportDialogOpen)
  if (isDialogOpen) {
    yield put(setAiReportNeedToRefresh(true))
  }
}

function* onUpdateUniqueAiObjectSuccess(action) {
  if (action.payload.Source !== AIReportDialog.name) {
    yield put(setAiReportNeedToRefresh(true))
  }
}

function* onPublishAiReportSuccess(action) {
  yield put(onAddSuccessNotification({ message: 'Report has been published successfully' }))
  yield put(closeAiReportPublishSettingsDialog())
}

function* onPublishAiReportFailed(action) {
  yield put(onAddErrorNotification({ message: 'Sorry, an error occurred while publishing the report' }))
}

function* onUpdateUniqueAiObjectFromJsonFailed(action) {
  yield put(
    onAddErrorNotification({ message: action.response.data.Error.Details || action.response.data.Error.Message })
  )
}

function* aiReportSagas() {
  yield all([
    takeLatest(selectAiObject, onSelectAiObject),
    takeLatest(getAiObjectsByContent.success, onGetAiObjectsByContentSuccess),
    takeLatest(goToAnnotation, onGoToAnnotation),
    takeLatest(updateUniqueAiObjectSuccess, onUpdateUniqueAiObjectSuccess),
    takeLatest(publishAiReport.success, onPublishAiReportSuccess),
    takeLatest(publishAiReport.failed, onPublishAiReportFailed),
    takeLatest(updateUniqueAiObjectFromJson.failed, onUpdateUniqueAiObjectFromJsonFailed),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUniqueAIObjectUpdated, onUniqueAIObjectUpdated),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationUpdate, onAnnotationMutate),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationDeleted, onAnnotationMutate),
  ])
}

export default aiReportSagas
